<template>
  <div id="app">
    <the-notification />
    <router-view />
  </div>
</template>

<script>
import './filters'

export default {
  name: 'App',
  components: {
    TheNotification: () => import('@/components/UI/TheNotifications.vue')
  },
  mounted () {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1024 && this.$store.state.ui.sidebar) {
        this.$store.commit('toggleSidebar')
      } else if (window.innerWidth >= 1024 && !this.$store.state.ui.sidebar) {
        this.$store.commit('toggleSidebar')
      }
    })
  }
}
</script>

<style lang="sass">
  $body-background-color: #f7f7f7;

  @import "bulma/bulma.sass";
  @import "bulma-switch/src/sass/index.sass";
</style>

<style lang="scss">
  // Fuentes
  @font-face {
    font-family: "Codec-Cold";
    src: local("Codec-Cold"),
    url(~@/assets/font/Codec/Codec-Cold-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: "Codec-Cold-Bold";
    src: local("Codec-Cold-Bold"),
    url(~@/assets/font/Codec/Codec-Cold-Bold.ttf) format("truetype");
  }
  @font-face {
    font-family: "Codec-Cold-ExtraBold";
    src: local("Codec-Cold-ExtraBold"),
    url(~@/assets/font/Codec/Codec-Cold-ExtraBold.ttf) format("truetype");
  }

  :root {
    --error-color: #ff3860;
    --primary-black: #262626;
    --primary-green: #458140;
    --secondary-green: #7cbc1f;
    --secondary-green-ligth: #8bb941;
    --green-light: #d0eac4;
    --green-dark: #253423;
    --grey: #818181;
    --primary-gray: #b5b5b5;
    --gray-light: #eeeeee;
    --orange: #ff9a36;
    --orange-dark: #ff7436;
    --red: #ff3131;
    --aqua: #a8fff8;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto !important;
    background-color: #f2f3f5;
    font-family: 'Roboto', sans-serif;
  }
  #app {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
  }
  #app > .hero {
    flex: 1;
  }

  h1, h2, h3, h4, h5, h6, a, p, span {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

  .is_grid {
    display: grid;
  }

  .hero {
    background-color: #f7f7f7;
  }
  .hero.is-fullheight .hero-body {
    align-items: flex-start;
  }

  /* Chrome Autocomplete */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px white inset;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: #363636 !important;
  }
  input, button, .button {
    font-family: 'Roboto', sans-serif;
  }

  .label {
    font-size: 18px;
    line-height: 1.17;
    font-weight: bold;
    color: var(--primary-black);
  }

  .input, .textarea {
    border-radius: 2px;
    border: solid 2px #cbcbcb;
  }
  .input:focus,
  .textarea:focus {
    border-radius: 2px;
    border: solid 2px var(--secondary-green);
    box-shadow: none;
  }
  .select.slct select {
    border-radius: 2px;
    border-color: none;
    border-width: 0px;
    border: 2px solid #cbcbcb;
  }
  .select.slct:not(.is-multiple):not(.is-loading)::after {
    border-color: var(--primary-green);
  }
  .select.slct.is-danger select {
    border: 2px solid #ff3860;
  }

  .input_reset, select {
    border-radius: 5px;
    border: solid 1px #e8e8e8;
  }
  .input_reset:focus {
    border-radius: 5px;
    border: solid 1px #b5b5b5;
    box-shadow: none;
  }
  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: var(--primary-black);
  }
  .calendar_input {
    /deep/ input {
      border-color: #e8e8e8;
    }
  }
  .textarea {
    resize: none;
  }

  .switch[type="checkbox"].is-small:checked + label::before,
  .switch[type="checkbox"].is-small:checked + label:before {
    background: #7CBC1F;
  }

  // Textos
  .title_component {
    font-weight: bold;
    font-size: 27px;
    line-height: 1.07;
    color: var(--primary-black);
  }
  .title_level {
    font-size: 25px;
    line-height: 1.16;
    color: var(--primary-black);
    font-weight: bolder;
  }

  .paragraph {
    font-size: 18px;
    line-height: 1.17;
    color: var(--primary-black);
  }
  .text_51 {
    font-size: 51px;
  }
  .text_20 {
    font-size: 20px;
    line-height: 1.2;
  }
  .text_18 {
    font-size: 18px;
    line-height: 1.19;
  }
  .text_16 {
    font-size: 16px;
    line-height: 1.19;
  }

  .emphasis_link {
    font-size: 18px;
    line-height: 1.5;
    font-weight: bold;
    text-decoration: underline;
    color: var(--primary-green);
  }
  .emphasis_link:hover {
    color: var(--primary-green);
  }

  .material-icons.is-warning {
    color: #eb9e23;
  }
  .material-icons.is-danger {
    color: #ff3860;
  }
  .material-icons.is-success {
    color: #00b00c;
  }

  .text_blue {
    color: #2699fb;
  }
  .text_green {
    color: #7cbc1f;
  }
  .text_error {
    color: #ff3131;
  }
  .text_orange {
    color: #ff9a36;
  }
  .text_yellow {
    color: #eda400;
  }
  .text_aqua_ligth {
    color: #48c9b0;
  }
  .text_cancel {
    color: #f14668;
  }
  .text_purple {
    color: #9126fb;
  }
  .text_primary_green {
    color: var(--primary-green);
  }
  .text_primary_black {
    color: var(--primary-black);
  }
  .text_green_dark {
    color: var(--green-dark);
  }

  // Botones
  .button {
    font-family: 'Roboto', sans-serif;
    white-space: normal;
    height: auto;
  }

  .button_micro_dark {
    margin-left: 5px;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to left, #262626 9%, #262626 96%);
    border: none;
    color: white;
    font-weight: 600;
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
  .button_micro_dark:hover,
  .button_micro_dark:focus,
  .button_micro_dark:active,
  .button_micro_dark.is-active {
    background-image: linear-gradient(to left, #262626 9%, #262626 96%);
    color: white;
    opacity: 0.9;
  }

  .button_micro_dark_outlined {
    background-color: transparent;
    border: 1px solid #262626;
    color: #262626;
    font-weight: 600;
    border-radius: 8px;
  }
  .button_micro_dark_outlined:hover,
  .button_micro_dark_outlined:focus,
  .button_micro_dark_outlined:active,
  .button_micro_dark_outlined.is-active {
    border: 1px solid #0a0a0a;
    color: #0a0a0a;
  }

  .button.is_green {
    background-color: #7CBC1E;
    border-color: #7CBC1E;
    color: white;
    font-size: 25px;
    padding-bottom: calc(0.1em - 1px);
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: calc(0.1em - 1px);
    height: 1.6em;
    outline: none;
  }
  .button.is_green:focus:not(:active),
  .button.is_green.is-focused:not(:active) {
    box-shadow: none;
  }

  // Tabla
  .table-container {
    padding: 0 8px;
  }

  // Modal
  .modal-card-title {
    font-size: 18px;
    line-height: 1.17;
    font-weight: bold;
    color: var(--green-dark);
  }
  .close_modal_icon {
    color: 707070;
    font-size: 30px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  }

  // Tabs
  .tabs li.is-active a, .tabs a:hover {
    font-size: 20px;
    font-weight: bold;
    border-width: 2px;
    border-bottom-color: #8bb941;
    color: #262626;
  }
  .tabs a {
    font-size: 20px;
    font-weight: bold;
    color: #818181;
    padding: 0em 0em;
    margin: 0.5rem 3rem 0.5rem 0;
    border-bottom-color: transparent;
  }

  .table_products_var thead tr th {
    background-color: var(--primary-black);
    color: white;
    border: none;
  }
  .table_products_var thead tr th:first-child {
    border-left: 1px solid #dbdbdb;
  }
  .table_products_var thead tr th:last-child {
    border-right: 1px solid #dbdbdb;
  }
  .table_products_var td {
    vertical-align: middle;
  }

  .file_product_var {
    border: 1px solid #dbdbdb;
    padding: 5px;
    .file-label .file-cta {
      background-color: transparent;
      border: none;
    }
  }

  @media screen and (max-width: 768px) {
    .button.is-medium {
      font-size: 1rem;
    }
    .label {
      font-size: 16px;
      line-height: 1.17;
    }
    // Textos
    .title_component {
      font-size: 25px;
      line-height: 1.07;
    }
    .title_level {
      font-size: 22px;
      line-height: 1.16;
    }
    .paragraph {
      font-size: 16px;
      line-height: 1.17;
    }
    .text_51 {
      font-size: 35px;
    }
    .text_20 {
      font-size: 18px;
      line-height: 1.2;
    }
    .text_18 {
      font-size: 16px;
      line-height: 1.19;
    }
    .text_16 {
      font-size: 14px;
      line-height: 1.19;
    }
    .emphasis_link {
      font-size: 16px;
      line-height: 1.5;
    }
    // Modal
    .modal-card-title {
      font-size: 16px;
      line-height: 1.17;
    }
    .close_modal_icon {
      color: #707070;
      font-size: 25px;
    }

    // Tabs
    .tabs li.is-active a, .tabs a:hover {
      font-size: 18px;
    }
    .tabs a {
      font-size: 18px;
    }

    .level-left + .level-right {
      margin-top: 0.7rem;
    }
  }
</style>
