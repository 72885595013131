import api from '@/lib/api'

export default {
  async createPublicationCategory ({ dispatch }, data) {
    try {
      const { success, message } = await api.createPublicationCategoryAPI(data)
      if (success) {
        dispatch('addTemporalNotification', { type: 'success', message })
        return success
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getPublicationCategories ({ dispatch }, { page = 1, limit = 10 }) {
    try {
      const payload = await api.getPublicationCategoriesAPI(page, limit)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updatePublicationCategory ({ dispatch }, data) {
    try {
      const payload = await api.updatePublicationCategoryAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async createPublication ({ dispatch }, data) {
    try {
      const { success, message } = await api.createPublicationAPI(data)
      if (success) {
        dispatch('addTemporalNotification', { type: 'success', message })
        return success
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getPublications ({ dispatch }, { page = 1, limit = 10, search = '', startDate = '', endDate = '', categoryId = '' }) {
    try {
      const payload = await api.getPublicationsAPI(page, limit, search, startDate, endDate, categoryId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getPublicationInfo ({ dispatch }, publicationId) {
    try {
      const payload = await api.getPublicationInfoAPI(publicationId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updatePublication ({ dispatch }, data) {
    try {
      const payload = await api.updatePublicationAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getPublicationsComments ({ dispatch }, { page = 1, limit = 10, search = '', startDate = '', endDate = '' }) {
    try {
      const payload = await api.getPublicationsCommentsAPI(page, limit, search, startDate, endDate)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updateStatusMessagePublication ({ dispatch }, messageId) {
    try {
      const payload = await api.updateStatusMessagePublicationAPI(messageId)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
