import api from '@/lib/api'
import utilities from '@/extras/utilities'

export default {
  async getDashboardInfo ({ dispatch, state }) {
    try {
      const { success, data } = await api.getDashboardInfoAPI()
      if (success) {
        utilities.objectUpdate(state, 'dashboard.totalRecords.quotes.total', Number(data.quotes))
        utilities.objectUpdate(state, 'dashboard.totalRecords.sales.total', Number(data.sales))
        utilities.objectUpdate(state, 'dashboard.totalRecords.subscribed.total', Number(data.subscribed))
        utilities.objectUpdate(state, 'dashboard.totalRecords.unsubscribed.total', Number(data.unsubscribed))
        utilities.objectUpdate(state, 'dashboard.bestSellers', data.bestSellers)
        utilities.objectUpdate(state, 'dashboard.mostValued', data.mostValued)
        return success
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
