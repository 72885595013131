const controller = {
  passwordGenerator (length = 10) {
    let passwd = ''
    const chars =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    for (let i = 1; i < length; i++) {
      var c = Math.floor(Math.random() * chars.length + 1)
      passwd += chars.charAt(c)
    }
    return passwd
  },
  objectValidate (A, keys, defaultValue) {
    if (A) {
      // Compatibilidad con rutas estilo 'a/b/c o a.b.c'
      if (typeof keys === 'string') {
        var separator = keys.indexOf('/') >= 0 ? '/' : false
        if (!separator) {
          separator = keys.indexOf('.') >= 0 ? '.' : false
        }
        keys = keys.split(separator)
      }

      if (!(keys instanceof Array)) {
        return defaultValue
      }

      for (var i in keys) {
        A = A[keys[i]]

        if (A === undefined) {
          return defaultValue
        }

        if (!A && i < keys.length - 1) {
          return defaultValue
        }
      }
      return A
    }
    return defaultValue
  },
  objectUpdate (A, keys, value) {
    if (typeof keys === 'string') {
      var separator = keys.indexOf('/') >= 0 ? '/' : false
      if (!separator) {
        separator = keys.indexOf('.') >= 0 ? '.' : false
      }
      keys = keys.split(separator)
    }
    var num = keys.length
    for (var i = 0; i < num - 1; i++) {
      if (A[keys[i]] === undefined) {
        A[keys[i]] = {}
      }
      A = A[keys[i]]
    }
    A[keys[num - 1]] = value
  },
  replaceText (text, search, replaceWith) {
    const result = text.split(search).join(replaceWith)
    return result
  }
}

module.exports = controller
