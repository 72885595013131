import ui from './ui'
import auth from './authentication'
import users from './users'
import customers from './customers'
import contact from './contact'
import socialMedia from './socialMedia'
import faqs from './faqs'
import publication from './publication'
import catalogs from './catalogs'
import cloud from './cloud'
import brands from './brands'
import lines from './lines'
import products from './products'
import guides from './guides'
import slides from './slides'
import sectors from './sectors'
import dollar from './dollar'
import quotes from './quotes'
import dashboard from './dashboard'
import sales from './sales'

export default {
  ...ui,
  ...auth,
  ...users,
  ...customers,
  ...contact,
  ...socialMedia,
  ...faqs,
  ...publication,
  ...catalogs,
  ...cloud,
  ...brands,
  ...lines,
  ...products,
  ...guides,
  ...slides,
  ...sectors,
  ...dollar,
  ...quotes,
  ...dashboard,
  ...sales
}
