import api from '@/lib/api'

export default {
  async getPriceDollar ({ dispatch }) {
    try {
      const payload = await api.getPriceDollarAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updatePriceDollar ({ dispatch }, data) {
    try {
      const payload = await api.updatePriceDollarAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
