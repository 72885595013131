const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getDashboardInfoAPI () {
    const response = await fetch(`${API_URL}/admin/dashboard`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  }
}
