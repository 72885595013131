export default {
  loaderSpinner: false,
  totalRecords: {
    subscribed: {
      name: 'Total usuarios registrados',
      icon: 'user_register_blue.png',
      total: 0,
      color: 'text_blue'
    },
    unsubscribed: {
      name: 'Total de usuarios',
      icon: 'total_users_green.png',
      total: 0,
      color: 'text_green'
    },
    sales: {
      name: 'Total de ventas',
      icon: 'sales_orange.png',
      total: 0,
      color: 'text_orange'
    },
    quotes: {
      name: 'Total de cotizaciones',
      icon: 'quotation_purple.png',
      total: 0,
      color: 'text_purple'
    }
  },
  bestSellers: [],
  mostValued: []
}
