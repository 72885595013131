const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async createPublicationCategoryAPI (data) {
    const response = await fetch(`${API_URL}/admin/category`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(data)
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getPublicationCategoriesAPI (page = 1, limit = 10) {
    const response = await fetch(`${API_URL}/admin/categories?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async updatePublicationCategoryAPI (data) {
    const { categoryId, ...dataUpdate } = data
    const response = await fetch(`${API_URL}/admin/categories/${categoryId}/update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(dataUpdate)
    })
    const resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async createPublicationAPI (data) {
    const { image, ...body } = data
    const formData = new FormData()

    formData.append('image', image.file)
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value)
    })

    const response = await fetch(`${API_URL}/admin/publication`, {
      method: 'POST',
      headers: {
        Authorization: getToken()
      },
      body: formData
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getPublicationsAPI (page = 1, limit = 10, search = '', startDate = '', endDate = '', categoryId = '') {
    const response = await fetch(`${API_URL}/admin/publication?page=${page}&limit=${limit}&search=${search}&categoryId=${categoryId}&startDate=${startDate}&endDate=${endDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getPublicationInfoAPI (publicationId) {
    const response = await fetch(`${API_URL}/admin/publication/${publicationId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async updatePublicationAPI (data) {
    const { publicationId, image, ...dataUpdate } = data
    const formData = new FormData()

    if (image) { formData.append('image', image.file) }
    Object.entries(dataUpdate).forEach(([key, value]) => {
      formData.append(key, value)
    })

    const response = await fetch(`${API_URL}/admin/publication/${publicationId}/update`, {
      method: 'PUT',
      headers: {
        Authorization: getToken()
      },
      body: formData
    })
    const resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async getPublicationsCommentsAPI (page = 1, limit = 10, search = '', startDate = '', endDate = '') {
    const response = await fetch(`${API_URL}/admin/comments/publication?page=${page}&limit=${limit}&search=${search}&startDate=${startDate}&endDate=${endDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async updateStatusMessagePublicationAPI (messageId) {
    const response = await fetch(`${API_URL}/admin/comments/publication/${messageId}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  }
}
