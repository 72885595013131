import products from './products'
import catalogs from './catalogs'
import dashboard from './dashboard'
import sales from './sales'

export default {
  ...products,
  ...catalogs,
  ...dashboard,
  ...sales
}
