const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getProductsGuideAPI () {
    const response = await fetch(`${API_URL}/admin/products/guide`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async createSelectionGuideAPI (data) {
    const {
      coverImage,
      guideFile,
      pdfFileDataSheet,
      relatedProducts,
      ...body
    } = data

    const formData = new FormData()
    // Imagenes
    if (coverImage) { formData.append('coverImage', coverImage.file) }
    if (guideFile) { formData.append('guideFile', guideFile.file) }
    if (pdfFileDataSheet) { formData.append('pdfFileDataSheet', pdfFileDataSheet.file) }
    // Productos relacionados
    if (relatedProducts.length > 0) {
      relatedProducts.forEach(item => {
        formData.append('relatedProducts', JSON.stringify(item))
      })
    }
    // Body
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value)
    })

    const response = await fetch(`${API_URL}/admin/guide`, {
      method: 'POST',
      headers: { Authorization: getToken() },
      body: formData
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getSelectionGuideAPI (page = 1, limit = 10, search = '', startDate = '', endDate = '') {
    const response = await fetch(`${API_URL}/admin/guides?page=${page}&limit=${limit}&search=${search}&startDate=${startDate}&endDate=${endDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getSelectionGuideDetailAPI (guideId) {
    const response = await fetch(`${API_URL}/admin/guide/${guideId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async updateSelectionGuideAPI (data) {
    const {
      guideId,
      coverImage,
      guideFile,
      pdfFileDataSheet,
      relatedProducts,
      ...body
    } = data

    const formData = new FormData()
    // Imagenes
    if (coverImage) { formData.append('coverImage', coverImage.file) }
    if (guideFile) { formData.append('guideFile', guideFile.file) }
    if (pdfFileDataSheet) { formData.append('pdfFileDataSheet', pdfFileDataSheet.file) }
    // Productos relacionados
    if (relatedProducts.length > 0) {
      relatedProducts.forEach(item => {
        formData.append('relatedProducts', JSON.stringify(item))
      })
    }
    // Body
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value)
    })

    const response = await fetch(`${API_URL}/admin/guide/${guideId}/update`, {
      method: 'PUT',
      headers: { Authorization: getToken() },
      body: formData
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  }
}
