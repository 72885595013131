import api from '@/lib/api'

export default {
  async getQuotes ({ dispatch }, { page = 1, limit = 10, search = '', startDate = '', endDate = '' }) {
    try {
      const payload = await api.getQuotesAPI(page, limit, search, startDate, endDate)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getQuoteDetail ({ dispatch }, quoteId) {
    try {
      const payload = await api.getQuoteDetailAPI(quoteId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async csvDownloadQuotes ({ dispatch }, { search = '', startDate = '', endDate = '' }) {
    try {
      const payload = await api.csvDownloadQuotesAPI(search, startDate, endDate)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updateStatusQuote ({ dispatch }, data) {
    try {
      const payload = await api.updateStatusQuoteAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
