const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async uploadFileAPI (image) {
    const formData = new FormData()
    formData.append('image', image)

    const response = await fetch(`${API_URL}/cloud/upload/file`, {
      method: 'POST',
      headers: {
        Authorization: getToken()
      },
      body: formData
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  }
}
