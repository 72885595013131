export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    redirect: 'dashboard',
    meta: { auth: true },
    children: [
      {
        path: '/',
        name: 'first-view',
        component: () => import('@/views/Dashboard/Dashboard.vue')
      },
      {
        path: 'mi-perfil',
        name: 'mi-perfil',
        component: () => import('@/views/MyProfile/MyProfile.vue')
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard/Dashboard.vue')
      },
      {
        path: '/productos',
        component: () => import('@/views/Products/ProductsIndex.vue'),
        children: [
          {
            path: '/',
            name: 'productos',
            component: () => import('@/views/Products/Products.vue')
          },
          {
            path: '/productos/agregar-producto',
            name: 'agregar-producto',
            component: () => import('@/views/Products/ProductsAdd.vue')
          },
          {
            path: '/productos/detalle-producto/:productId',
            name: 'detalle-producto',
            component: () => import('@/views/Products/ProductsDetail.vue')
          },
          {
            path: '/productos/marcas',
            name: 'marcas-productos',
            component: () => import('@/views/Products/Brand/Brand.vue')
          },
          {
            path: '/productos/lineas',
            component: () => import('@/views/Products/Lines/LinesIndex.vue'),
            redirect: 'lineas-productos',
            children: [
              {
                path: '/',
                name: 'lineas-productos',
                component: () => import('@/views/Products/Lines/Lines.vue')
              },
              {
                path: '/productos/agregar-lineas',
                name: 'agregar-lineas',
                component: () => import('@/views/Products/Lines/LineAdd.vue')
              },
              {
                path: '/productos/lineas/:lineId/detalle',
                name: 'detalle-linea',
                component: () => import('@/views/Products/Lines/LineDetail.vue')
              }
            ]
          },
          {
            path: '/productos/sectores',
            name: 'sectores-productos',
            component: () => import('@/views/Products/Sectors/Sectors.vue')
          }
        ]
      },
      {
        path: '/ventas',
        component: () => import('@/views/Sales/SalesIndex.vue'),
        children: [
          {
            path: '/',
            name: 'ventas',
            component: () => import('@/views/Sales/Sales.vue')
          },
          {
            path: 'detalle-venta/:saleId',
            name: 'detalle-venta',
            component: () => import('@/views/Sales/SaleDetail.vue')
          }
        ]
      },
      {
        path: '/cotizaciones',
        component: () => import('@/views/Quotation/QuotationIndex.vue'),
        children: [
          {
            path: '/',
            name: 'cotizaciones',
            component: () => import('@/views/Quotation/Quotation.vue')
          },
          {
            path: 'detalle-cotizacion/:quotationId',
            name: 'detalle-cotizacion',
            component: () => import('@/views/Quotation/QuotationDetail.vue')
          }
        ]
      },
      {
        path: '/publicaciones',
        component: () => import('@/views/Publications/PublicationsIndex.vue'),
        children: [
          {
            path: '/',
            name: 'publicaciones',
            component: () => import('@/views/Publications/Publications.vue')
          },
          {
            path: 'detalle-publicacion/:publicationId',
            name: 'detalle-publicacion',
            component: () => import('@/views/Publications/PublicationDetail.vue')
          },
          {
            path: 'agregar-publicacion',
            name: 'agregar-publicacion',
            component: () => import('@/views/Publications/PublicationAdd.vue')
          },
          {
            path: '/publicaciones/categorias',
            name: 'categorias',
            component: () => import('@/views/Publications/Categories/Categories.vue')
          }
        ]
      },
      {
        path: '/guias-de-seleccion',
        component: () => import('@/views/SelectionGuide/SelectionGuideIndex.vue'),
        children: [
          {
            path: '/',
            name: 'guias-de-seleccion',
            component: () => import('@/views/SelectionGuide/SelectionGuide.vue')
          },
          {
            path: 'detalle-guia-de-seleccion/:selectionGuideId',
            name: 'detalle-guia-de-seleccion',
            component: () => import('@/views/SelectionGuide/SelectionGuideDetail.vue')
          },
          {
            path: 'agregar-guia-de-seleccion',
            name: 'agregar-guia-de-seleccion',
            component: () => import('@/views/SelectionGuide/SelectionGuideAdd.vue')
          }
        ]
      },
      {
        path: '/preguntas-frecuentes',
        component: () => import('@/views/FrequentQuestions/FrequentQuestionsIndex.vue'),
        children: [
          {
            path: '/',
            name: 'preguntas-frecuentes',
            component: () => import('@/views/FrequentQuestions/FrequentQuestions.vue')
          }
        ]
      },
      {
        path: '/redes-sociales',
        component: () => import('@/views/SocialMedia/SocialMediaIndex.vue'),
        children: [
          {
            path: '/',
            name: 'redes-sociales',
            component: () => import('@/views/SocialMedia/SocialMedia.vue')
          }
        ]
      },
      {
        path: '/suscriptores',
        component: () => import('@/views/Subscribers/SubscribersIndex.vue'),
        children: [
          {
            path: '/',
            name: 'suscriptores',
            component: () => import('@/views/Subscribers/Subscribers.vue')
          }
        ]
      },
      {
        path: '/contacto',
        component: () => import('@/views/Contact/ContactIndex.vue'),
        children: [
          {
            path: '/',
            name: 'contacto',
            component: () => import('@/views/Contact/Contact.vue')
          },
          {
            path: 'contacto-mensaje/:messageId',
            name: 'contacto-mensaje',
            component: () => import('@/views/Contact/ContactMessage.vue')
          }
        ]
      },
      {
        path: '/pagina-web',
        component: () => import('@/views/WebPage/WebPageIndex.vue'),
        children: [
          {
            path: '/',
            name: 'pagina-web',
            component: () => import('@/views/WebPage/WebPage.vue')
          }
        ]
      },
      {
        path: '/mensajes',
        component: () => import('@/views/Messages/MessagesIndex.vue'),
        children: [
          {
            path: '/',
            name: 'mensajes',
            component: () => import('@/views/Messages/Messages.vue')
          }
        ]
      },
      {
        path: '/usuarios',
        component: () => import('@/views/Users/UsersIndex.vue'),
        children: [
          {
            path: '/',
            name: 'usuarios',
            component: () => import('@/views/Users/Users.vue')
          },
          {
            path: 'detalle-usuario/:userId',
            name: 'detalle-usuario',
            component: () => import('@/views/Users/UserDetail.vue')
          }
        ]
      },
      {
        path: '/administradores',
        component: () => import('@/views/AdminUsers/AdminUsersIndex.vue'),
        children: [
          {
            path: '/',
            name: 'administradores',
            component: () => import('@/views/AdminUsers/AdminUsers.vue')
          }
        ]
      },
      {
        path: '/dolar',
        component: () => import('@/views/Dollar/DollarIndex.vue'),
        children: [
          {
            path: '/',
            name: 'dolar',
            component: () => import('@/views/Dollar/Dollar.vue')
          }
        ]
      }
    ]
  }
]
