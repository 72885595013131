import api from '@/lib/api'

export default {
  async getSlides ({ dispatch }) {
    try {
      const payload = await api.getSlidesAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updateSlide ({ dispatch }, data) {
    try {
      const payload = await api.updateSlideAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async addSlide ({ dispatch }, data) {
    try {
      const payload = await api.addSlideAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
