import api from '@/lib/api'

export default {
  async getCustomersUsers ({ dispatch }, { page = 1, limit = 10, search = '' }) {
    try {
      const payload = await api.getCustomersUsersAPI(page, limit, search)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getCustomerInfo ({ dispatch }, userId) {
    try {
      const payload = await api.getCustomerInfoAPI(userId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async updateCustomerStatus ({ dispatch }, userId) {
    try {
      const payload = await api.updateCustomerStatusAPI(userId)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getSubscribers ({ dispatch }, { page = 1, limit = 10, search = '', startDate = '', endDate = '' }) {
    try {
      const payload = await api.getSubscribersAPI(page, limit, search, startDate, endDate)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async csvDownloadCustomers ({ dispatch }, { search = '' }) {
    try {
      const payload = await api.csvDownloadCustomersAPI(search)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async csvDownloadSuscribers ({ dispatch }, { search = '', startDate = '', endDate = '' }) {
    try {
      const payload = await api.csvDownloadSuscribersAPI(search, startDate, endDate)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
