const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getContactListAPI (page = 1, limit = 10, search = '', startDate = '', endDate = '') {
    const response = await fetch(`${API_URL}/admin/contact?page=${page}&limit=${limit}&search=${search}&startDate=${startDate}&endDate=${endDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getContactDetailAPI (contactId) {
    const response = await fetch(`${API_URL}/admin/contact/${contactId}/detail`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  }
}
