import api from '@/lib/api'

export default {
  async uploadFile ({ dispatch }, data) {
    try {
      const { success, image } = await api.uploadFileAPI(data)
      if (success) {
        return { success, image }
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
