const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async createProductLineAPI (data) {
    const { image, subLinesList, ...body } = data
    const formData = new FormData()
    // Imagen
    if (image && image.file) {
      formData.append('image', image.file)
    }
    // Lineas
    if (subLinesList.length > 0) {
      subLinesList.forEach(item => {
        formData.append('subLinesList', JSON.stringify(item))
      })
    }
    // Body
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value)
    })

    const response = await fetch(`${API_URL}/admin/line`, {
      method: 'POST',
      headers: { Authorization: getToken() },
      body: formData
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getProductLinesAPI (page = 1, limit = 10) {
    const response = await fetch(`${API_URL}/admin/lines?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getProductLineDetailAPI (lineId) {
    const response = await fetch(`${API_URL}/admin/line/${lineId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async updateProductLineAPI (data) {
    const { lineId, image, subLinesList, ...dataUpdate } = data
    const formData = new FormData()
    // Imagen
    if (image) { formData.append('image', image.file) }
    // Lineas
    if (subLinesList.length > 0) {
      subLinesList.forEach(item => {
        formData.append('subLinesList', JSON.stringify(item))
      })
    }
    // Body
    Object.entries(dataUpdate).forEach(([key, value]) => {
      formData.append(key, value)
    })

    const response = await fetch(`${API_URL}/admin/line/${lineId}/update`, {
      method: 'PUT',
      headers: { Authorization: getToken() },
      body: formData
    })
    const resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async getProductLineWithSublinesAPI () {
    const response = await fetch(`${API_URL}/admin/lines/with-sublines/available`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  }
}
