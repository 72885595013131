import api from '@/lib/api'
import _ from 'lodash'

export default {
  async getCategoriesAvailable ({ dispatch }) {
    try {
      const payload = await api.getCategoriesAvailableAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', {
        type: 'danger',
        message: error.message
      })
      return false
    }
  },
  async getCategoriesCatalog ({ dispatch }) {
    try {
      const payload = await api.getCategoriesCatalogAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', {
        type: 'danger',
        message: error.message
      })
      return false
    }
  },
  async getProductBrandCatalog ({ dispatch, commit }) {
    try {
      const payload = await api.getProductBrandCatalogAPI()
      commit('CATALOGS_SET_STATE', {
        brandsList: payload.brands
      })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', {
        type: 'danger',
        message: error.message
      })
      return false
    }
  },
  async getAllBrandsCatalog ({ dispatch, commit }) {
    try {
      const payload = await api.getAllProductBrandCatalogAPI()
      commit('CATALOGS_SET_STATE', {
        brandsList: payload.brands
      })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', {
        type: 'danger',
        message: error.message
      })
      return false
    }
  },
  async getProductLinesCatalog ({ dispatch, commit }) {
    try {
      const payload = await api.getProductLinesCatalogAPI()
      commit('CATALOGS_SET_STATE', {
        linesList: payload.lines
      })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', {
        type: 'danger',
        message: error.message
      })
      return false
    }
  },
  async getAllProductLinesCatalog ({ dispatch, commit }) {
    try {
      const payload = await api.getAllProductLinesCatalogAPI()
      commit('CATALOGS_SET_STATE', {
        linesList: payload.lines
      })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', {
        type: 'danger',
        message: error.message
      })
      return false
    }
  },
  async getCurrency ({ dispatch }) {
    try {
      const payload = await api.getCurrencyAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', {
        type: 'danger',
        message: error.message
      })
      return false
    }
  },
  async getProductSetorCatalog ({ dispatch }) {
    try {
      const payload = await api.getProductSetorCatalogAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', {
        type: 'danger',
        message: error.message
      })
      return false
    }
  },
  async getStatusSaleQuote ({ dispatch }) {
    try {
      const payload = await api.getStatusSaleQuoteAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getCurrentDollarValue ({ dispatch, commit }) {
    try {
      const payload = await api.getCurrentDollarAPI()
      commit('CATALOGS_SET_STATE', {
        currentDollarValue: payload.costDollar
      })
    } catch (error) {
      dispatch('addTemporalNotification', {
        type: 'danger',
        message: error.message
      })
    }
  },
  async updateCurrentDollarValue ({ dispatch, commit, state }, costDollar) {
    commit('CATALOGS_SET_STATE', {
      spinnerLoader: true,
      spinnerLoaderLabel: 'Actualizando datos'
    })
    try {
      const payload = await api.updateCurrentDollarAPI(costDollar)
      dispatch('addTemporalNotification', {
        type: 'success',
        message: payload.message
      })
      const newDollarValue = {
        ...state.currentDollarValue,
        cost_dollar: costDollar
      }
      commit('CATALOGS_SET_STATE', {
        currentDollarValue: _.cloneDeep(newDollarValue),
        spinnerLoader: false
      })
    } catch (error) {
      commit('CATALOGS_SET_STATE', {
        spinnerLoader: false
      })
      dispatch('addTemporalNotification', {
        type: 'danger',
        message: error.message
      })
    }
  }
}
