const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getCategoriesAvailableAPI () {
    const response = await fetch(`${API_URL}/catalogs/categories/available`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },
  async getCategoriesCatalogAPI () {
    const response = await fetch(`${API_URL}/catalogs/categories`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },
  async getProductBrandCatalogAPI () {
    const response = await fetch(`${API_URL}/catalogs/product/brands`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },
  async getAllProductBrandCatalogAPI () {
    const response = await fetch(`${API_URL}/catalogs/product/brands/all`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const resp = await response.json()

    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }

    return resp
  },
  async getProductLinesCatalogAPI () {
    const response = await fetch(`${API_URL}/catalogs/product/lines`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },
  async getAllProductLinesCatalogAPI () {
    const response = await fetch(`${API_URL}/catalogs/product/lines/all`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const resp = await response.json()

    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }

    return resp
  },
  async getCurrencyAPI () {
    const response = await fetch(`${API_URL}/catalogs/currency`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },
  async getProductSetorCatalogAPI () {
    const response = await fetch(`${API_URL}/catalogs/product/sectors`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },
  async getCurrentDollarAPI () {
    const response = await fetch(`${API_URL}/admin/dollar`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },
  async updateCurrentDollarAPI (costDollar) {
    const response = await fetch(`${API_URL}/admin/dollar`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify({ costDollar })
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getStatusSaleQuoteAPI () {
    const response = await fetch(`${API_URL}/catalogs/status/sale-quote`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  }
}
