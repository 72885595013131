const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getMyProfileDataAPI () {
    const response = await fetch(`${API_URL}/admin/users/profile/detail`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async myProfileUpdateAPI (data) {
    const response = await fetch(`${API_URL}/admin/users/profile/update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(data)
    })
    const resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async passwordUpdateAPI (data) {
    const response = await fetch(`${API_URL}/admin/users/profile/update-password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(data)
    })
    const resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async userForgotAPI (email) {
    const response = await fetch(`${API_URL}/admin/users/forgot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    })
    const result = await response.json()
    if (response.ok) {
      return result
    } else if (response.status === 400) {
      return Promise.reject(new Error(result.message))
    } else {
      return Promise.reject(new Error('Request Error'))
    }
  },

  async passwordRecoveryAPI ({ token, password }) {
    const response = await fetch(`${API_URL}/users/reset`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', token: token },
      body: JSON.stringify({ password })
    })
    const result = await response.json()
    if (response.ok) {
      return result
    } else if (response.status === 400) {
      return Promise.reject(new Error(result.error))
    } else {
      return Promise.reject(new Error('Request Error'))
    }
  },

  async getAdminUsersAPI (page = 1, limit = 10, search = '') {
    const response = await fetch(`${API_URL}/admin/users?page=${page}&limit=${limit}&search=${search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async createUserAdminAPI (data) {
    const response = await fetch(`${API_URL}/admin/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(data)
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async adminUserUpdateAPI (data) {
    const { id, ...dataUpdate } = data
    const response = await fetch(`${API_URL}/admin/users/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(dataUpdate)
    })
    const resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  }
}
