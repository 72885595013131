const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getSlidesAPI () {
    const response = await fetch(`${API_URL}/admin/slider`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async updateSlideAPI (data) {
    const { slideId, image, ...dataUpdate } = data
    const formData = new FormData()

    if (image) { formData.append('image', image.file) }
    Object.entries(dataUpdate).forEach(([key, value]) => {
      formData.append(key, value)
    })

    const response = await fetch(`${API_URL}/admin/slider/${slideId}/update`, {
      method: 'PUT',
      headers: {
        Authorization: getToken()
      },
      body: formData
    })
    const resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async addSlideAPI (data) {
    const { image, ...dataToAdd } = data
    const formData = new FormData()

    if (image) { formData.append('image', image.file) }
    Object.entries(dataToAdd).forEach(([key, value]) => {
      formData.append(key, value)
    })
    const response = await fetch(`${API_URL}/admin/slider/add`, {
      method: 'POST',
      headers: {
        Authorization: getToken()
      },
      body: formData
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  }
}
