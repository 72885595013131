import api from '@/lib/api'

export default {
  async createFaq ({ dispatch }, data) {
    try {
      const { success, message } = await api.createFaqAPI(data)
      if (success) {
        dispatch('addTemporalNotification', { type: 'success', message })
        return success
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getFaqs ({ dispatch }, { page = 1, limit = 10, search = '' }) {
    try {
      const payload = await api.getFaqsAPI(page, limit, search)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updateFaq ({ dispatch }, data) {
    try {
      const payload = await api.updateFaqAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
