const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async createProductAPI (data) {
    const {
      dataSheetFile,
      linesSelect,
      sectorList,
      keywordsList,
      imageFileMain,
      imageFileExtraOne,
      imageFileExtraTwo,
      pdfFileroductVariation,
      productVariableTables,
      ...body
    } = data

    const formData = new FormData()
    // Imagenes y PDFs
    if (dataSheetFile) { formData.append('dataSheetFile', dataSheetFile.file) }
    if (imageFileMain) { formData.append('imageFileMain', imageFileMain.file) }
    if (imageFileExtraOne) { formData.append('imageFileExtraOne', imageFileExtraOne.file) }
    if (imageFileExtraTwo) { formData.append('imageFileExtraTwo', imageFileExtraTwo.file) }
    if (pdfFileroductVariation) { formData.append('pdfFileroductVariation', pdfFileroductVariation.file) }
    // Lineas
    if (linesSelect.length > 0) {
      linesSelect.forEach(item => {
        formData.append('linesSelect', JSON.stringify(item))
      })
    }
    // Sectores
    if (sectorList.length > 0) {
      sectorList.forEach(item => {
        formData.append('sectorList', JSON.stringify(item))
      })
    }
    // Palabras clave
    formData.append('keywordsList', keywordsList)
    // Variable de productos
    if (productVariableTables.length > 0) {
      productVariableTables.forEach(item => {
        formData.append('productVariableTables', JSON.stringify(item))
      })
    }
    // Body
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value)
    })

    const response = await fetch(`${API_URL}/admin/products`, {
      method: 'POST',
      headers: { Authorization: getToken() },
      body: formData
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getProductDetailAPI (productId) {
    const response = await fetch(`${API_URL}/admin/product/${productId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async updateProductAPI (data) {
    const {
      productId,
      dataSheetFile,
      linesSelect,
      sectorList,
      keywordsList,
      imageFileMain,
      imageFileExtraOne,
      imageFileExtraTwo,
      pdfFileroductVariation,
      productVariableTables,
      ...body
    } = data

    const formData = new FormData()
    // Imagenes y PDFs
    if (dataSheetFile) { formData.append('dataSheetFile', dataSheetFile.file) }
    if (imageFileMain) { formData.append('imageFileMain', imageFileMain.file) }
    if (imageFileExtraOne) { formData.append('imageFileExtraOne', imageFileExtraOne.file) }
    if (imageFileExtraTwo) { formData.append('imageFileExtraTwo', imageFileExtraTwo.file) }
    if (pdfFileroductVariation) { formData.append('pdfFileroductVariation', pdfFileroductVariation.file) }
    // Lineas
    if (linesSelect.length > 0) {
      linesSelect.forEach(item => {
        formData.append('linesSelect', JSON.stringify(item))
      })
    }
    // Sectores
    if (sectorList.length > 0) {
      sectorList.forEach(item => {
        formData.append('sectorList', JSON.stringify(item))
      })
    }
    // Palabras clave
    formData.append('keywordsList', keywordsList)
    // Variable de productos
    if (productVariableTables.length > 0) {
      productVariableTables.forEach(item => {
        formData.append('productVariableTables', JSON.stringify(item))
      })
    }
    // Body
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value)
    })

    const response = await fetch(`${API_URL}/admin/product/${productId}/update`, {
      method: 'PUT',
      headers: { Authorization: getToken() },
      body: formData
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getProductsCommentsAPI (page = 1, limit = 10, search = '', startDate = '', endDate = '') {
    const response = await fetch(`${API_URL}/admin/comments/products?page=${page}&limit=${limit}&search=${search}&startDate=${startDate}&endDate=${endDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async updateStatusMessageProductAPI (messageId) {
    const response = await fetch(`${API_URL}/admin/comments/product/${messageId}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async getProductsListAPI (payload) {
    const response = await fetch(`${API_URL}/admin/products/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(payload)
    })
    const resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async getProductsListSimplifiedAPI () {
    const response = await fetch(`${API_URL}/admin/products/list/simplified`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  },

  async importProductsListAPI (payload) {
    const response = await fetch(`${API_URL}/admin/products/import`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(payload)
    })
    const resp = await response.json()

    if (!response.ok) {
      this.isLoading = false
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  }
}
