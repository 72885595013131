import auth from './authentication'
import ui from './ui'
import products from './products'
import catalogs from './catalogs'
import dashboard from './dashboard'
import sales from './sales'

export default {
  ...auth,
  ...ui,
  ...products,
  ...catalogs,
  ...dashboard,
  ...sales
}
