import api from '@/lib/api'

export default {
  async getSocialMedia ({ dispatch }) {
    try {
      const payload = await api.getSocialMediaAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updateSocialMedia ({ dispatch }, data) {
    try {
      const { message } = await api.updateSocialMediaAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
