import api from '@/lib/api'

export default {
  async getProductsGuide ({ dispatch }) {
    try {
      const payload = await api.getProductsGuideAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async createSelectionGuide ({ dispatch }, data) {
    try {
      const { success, message } = await api.createSelectionGuideAPI(data)
      if (success) {
        dispatch('addTemporalNotification', { type: 'success', message })
        return success
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getSelectionGuide ({ dispatch }, { page = 1, limit = 10, search = '', startDate = '', endDate = '' }) {
    try {
      const payload = await api.getSelectionGuideAPI(page, limit, search, startDate, endDate)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getSelectionGuideDetail ({ dispatch }, selectionId) {
    try {
      const payload = await api.getSelectionGuideDetailAPI(selectionId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updateSelectionGuide ({ dispatch }, data) {
    try {
      const payload = await api.updateSelectionGuideAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
