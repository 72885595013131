import api from '@/lib/api'
import _ from 'lodash'
import Swal from 'sweetalert2'

export default {
  async createProduct ({ dispatch }, data) {
    try {
      const { success, message } = await api.createProductAPI(data)
      if (success) {
        dispatch('addTemporalNotification', { type: 'success', message })
        return success
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getProductDetail ({ dispatch }, productId) {
    try {
      const payload = await api.getProductDetailAPI(productId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updateProduct ({ dispatch }, data) {
    try {
      const payload = await api.updateProductAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getProductsComments ({ dispatch }, { page = 1, limit = 10, search = '', startDate = '', endDate = '' }) {
    try {
      const payload = await api.getProductsCommentsAPI(page, limit, search, startDate, endDate)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updateStatusMessageProduct ({ dispatch }, messageId) {
    try {
      const payload = await api.updateStatusMessageProductAPI(messageId)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getProductsList ({ dispatch, commit }, payload) {
    commit('PRODUCTS_SET_STATE', {
      loaderSpinner: true
    })
    try {
      const response = await api.getProductsListAPI(payload)
      commit('PRODUCTS_SET_STATE', {
        productsList: _.cloneDeep(response.data),
        loaderSpinner: false
      })
    } catch (error) {
      console.error(error)
      dispatch('addTemporalNotification', { type: 'danger', message: error.message }, { root: true })
    }
  },

  async getProductsListSimplified ({ dispatch, commit }) {
    commit('PRODUCTS_SET_STATE', {
      loaderSpinner: true
    })
    try {
      const response = await api.getProductsListSimplifiedAPI()
      commit('PRODUCTS_SET_STATE', {
        productsListSimplified: _.cloneDeep(response.data),
        loaderSpinner: false
      })
    } catch (error) {
      console.error(error)
      dispatch('addTemporalNotification', { type: 'danger', message: error.message }, { root: true })
    }
  },

  async importProductsList ({ dispatch, commit }, payload) {
    commit('PRODUCTS_SET_STATE', {
      loaderSpinner: true
    })
    try {
      await api.importProductsListAPI(payload)
      Swal.fire({
        title: 'Productos',
        text: 'Se han importado los registros de manera exitosa',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'button button_micro_dark'
        }
      }).then(async (result) => {
        commit('PRODUCTS_SET_STATE', {
          loaderSpinner: false
        })
      })
    } catch (error) {
      console.error(error)
      commit('PRODUCTS_SET_STATE', {
        loaderSpinner: false
      })
      dispatch('addTemporalNotification', { type: 'danger', message: error.message }, { root: true })
    }
  }
}
