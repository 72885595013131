import api from '@/lib/api'
import _ from 'lodash'

export default {
  async getSales ({ dispatch, commit }, payload) {
    commit('SALES_SET_STATE', {
      loaderSpinner: true
    })
    try {
      const { data } = await api.getSalesAPI(payload)
      commit('SALES_SET_STATE', {
        loaderSpinner: false,
        salesList: _.cloneDeep(data.sales),
        salesTotalPages: data.totalPages
      })
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      commit('SALES_SET_STATE', {
        loaderSpinner: false
      })
    }
  },
  async getSalesStatus ({ dispatch, commit }) {
    commit('SALES_SET_STATE', {
      loaderSpinner: true
    })
    try {
      const { data } = await api.getSalesStatusAPI()
      commit('SALES_SET_STATE', {
        loaderSpinner: false,
        salesStatusList: _.cloneDeep(data)
      })
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      commit('SALES_SET_STATE', {
        loaderSpinner: false
      })
    }
  },
  async updateSaleStatus ({ dispatch, commit }, payload) {
    commit('SALES_SET_STATE', {
      loaderSpinner: true
    })
    try {
      const { message } = await api.updateSaleStatusAPI(payload)
      dispatch('addTemporalNotification', { type: 'success', message })
      commit('SALES_SET_STATE', {
        loaderSpinner: false
      })
      dispatch('getSaleDetail', payload.saleId)
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      commit('SALES_SET_STATE', {
        loaderSpinner: false
      })
    }
  },
  async getSaleDetail ({ dispatch, commit }, id) {
    commit('SALES_SET_STATE', {
      loaderSpinner: true
    })
    try {
      const { data } = await api.getSaleDetailAPI(id)
      commit('SALES_SET_STATE', {
        loaderSpinner: false,
        actualSale: _.cloneDeep(data)
      })
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      commit('SALES_SET_STATE', {
        loaderSpinner: false
      })
    }
  },
  async csvDownloadSales ({ dispatch }, { search = '', startDate = '', endDate = '' }) {
    try {
      const payload = await api.csvDownloadSalesAPI(search, startDate, endDate)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
