const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async createProductBrandAPI (data) {
    const response = await fetch(`${API_URL}/admin/brand`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(data)
    })

    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getProductBrandsAPI (page = 1, limit = 10) {
    const response = await fetch(`${API_URL}/admin/brands?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getProductBrandSelectAPI () {
    const response = await fetch(`${API_URL}/admin/brands/select`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async updateProductBrandAPI (data) {
    const { brandId, ...dataUpdate } = data
    const response = await fetch(`${API_URL}/admin/brand/${brandId}/update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken()
      },
      body: JSON.stringify(dataUpdate)
    })
    const resp = await response.json()

    if (!response.ok) {
      if (response.status === 400) {
        throw new Error(resp.error)
      }
    }
    return resp
  }
}
