import api from '@/lib/api'

export default {
  async getMyProfileData ({ dispatch }) {
    try {
      const payload = await api.getMyProfileDataAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async myProfileUpdate ({ dispatch }, data) {
    try {
      const { message } = await api.myProfileUpdateAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async passwordUpdate ({ dispatch }, data) {
    try {
      const { message } = await api.passwordUpdateAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async userForgot ({ dispatch }, email) {
    try {
      const { message } = await api.userForgotAPI(email)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async passwordRecovery ({ dispatch }, data) {
    try {
      const { message } = await api.passwordRecoveryAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getAdminUsers ({ dispatch }, { page = 1, limit = 10, search = '' }) {
    try {
      const payload = await api.getAdminUsersAPI(page, limit, search)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async createUserAdmin ({ dispatch }, data) {
    try {
      const { success, message } = await api.createUserAdminAPI(data)
      if (success) {
        dispatch('addTemporalNotification', { type: 'success', message })
        return success
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async adminUserUpdate ({ dispatch }, data) {
    try {
      const { message } = await api.adminUserUpdateAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
