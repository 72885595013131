import api from '@/lib/api'

export default {
  async createProductLine ({ dispatch }, data) {
    try {
      const { success, message } = await api.createProductLineAPI(data)
      if (success) {
        dispatch('addTemporalNotification', { type: 'success', message })
        return success
      }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getProductLines ({ dispatch }, { page = 1, limit = 10 }) {
    try {
      const payload = await api.getProductLinesAPI(page, limit)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getProductLineDetail ({ dispatch }, lineId) {
    try {
      const payload = await api.getProductLineDetailAPI(lineId)
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async updateProductLine ({ dispatch }, data) {
    try {
      const payload = await api.updateProductLineAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: payload.message })
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getProductLineWithSublines ({ dispatch }) {
    try {
      const payload = await api.getProductLineWithSublinesAPI()
      return payload
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
